<template>
  <div class="MonitorDelete" v-if="monitor">
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <div class="description">
              If you delete your monitor we will stop checking your site and remove all associated data. Be careful with this action.
              If you still want to proceed, type "delete" in the input below.
            </div>

            <monitor-delete-form :monitor="monitor" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import monitorsApi from '@/api/monitorsApi.js'
import MonitorDeleteForm from '@/components/Forms/MonitorDeleteForm.vue'

export default {
  components: {
    MonitorDeleteForm
  },

  metaInfo: {
    title: 'Delete Monitor'
  },

  props: {
    monitorId: {
      required: true
    }
  },

  data () {
    return {
      monitor: null
    }
  },

  async created () {
    this.monitor = await monitorsApi.find(this.monitorId)
  },

  methods: {
    onWindowAdd () {
      this.$refs.windowList.loadWindows()
    }
  }
}
</script>

<style lang="scss" scoped>
  .MonitorDelete {
    .description {
      line-height: 1.5;
      margin-bottom: 20px;
    }
  }
</style>
