<template>
  <div class="MonitorDeleteForm">
    <form @submit.prevent="onFormSubmit">
      <base-textbox v-model="confirmation"
                    ref="confirmation"
                    placeholder="Type delete" />
      <base-button type="submit"
                   color="danger"
                   class="button-delete"
                   :disabled="deleteButtonDisabled"
                   :loading="deleting">Delete</base-button>
    </form>
    <base-alert type="error" v-if="deleteError" class="mt-3">
      Something went wrong
    </base-alert>
  </div>
</template>

<script>
import monitorsApi from '@/api/monitorsApi.js'
import Swal from 'sweetalert2'

export default {
  props: {
    monitor: {
      required: true,
      type: Object
    }
  },

  data () {
    return {
      confirmation: '',
      deleting: false,
      deleteError: false
    }
  },

  mounted () {
    this.$refs.confirmation.focus()
  },

  methods: {
    async onFormSubmit () {
      this.deleteError = false

      if (this.confirmation !== 'delete') {
        return false
      }

      this.deleting = true
      try {
        await monitorsApi.delete(this.monitor.id)
        this.showSuccessMessage()
      } catch (e) {
        this.deleteError = true
      }
      await this.refreshCurrentUser()
      this.deleting = false
    },

    showSuccessMessage () {
      Swal.fire({
        title: 'Success',
        text: 'The monitor was successfully deleted',
        showClass: {
          popup: ''
        },
        hideClass: {
          popup: ''
        },
        confirmButtonColor: '#6652e0',
        allowOutsideClick: false
      }).then((result) => {
        if (result.value) {
          this.$router.push({
            name: 'monitors.list'
          })
        }
      })
    },

    ...mapActions('authentication', [
      'refreshCurrentUser'
    ])
  },

  computed: {
    deleteButtonDisabled () {
      return this.confirmation !== 'delete'
    }
  }
}
</script>

<style lang="scss" scoped>
  .button-delete {
    width: 150px;
  }
</style>
